import {
  faCalendarCheck as falCalendarCheck,
  faCalendarHeart as falCalendarHeart,
  faTriangleExclamation as falTriangleExclamation,
} from '@fortawesome/pro-light-svg-icons'
import {
  faArrowDown as farArrowDown,
  faArrowLeft as farArrowLeft,
  faArrowRight as farArrowRight,
  faArrowUp as farArrowUp,
  faArrowUpRightFromSquare as farArrowUpRightFromSquare,
  faBadgeCheck as farBadgeCheck,
  faBadgePercent as farBadgePercent,
  faChevronRight as farChevronRight,
  faEyeSlash as farEyeSlash,
  faFamilyPants as farFamilyPants,
  faGiftCard as farGiftCard,
  faHouse as farHouse,
  faHouseLock as farHouseLock,
  faInfoCircle as farInfoCircle,
  faLayerGroup as farLayerGroup,
  faMoonStars as farMoonStars,
  faPaw as farPaw,
  faPencil as farPencil,
  faSearch as farSearch,
  faUtensils as farUtensils,
  faXmark as farXmark,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBadgeCheck as fasBadgeCheck,
  faBadgePercent as fasBadgePercent,
  faCalendarHeart as fasCalendarHeart,
  faGiftCard as fasGiftCard,
  faHouse as fasHouse,
  faUser as fasUser,
  faUtensils as fasUtensils,
} from '@fortawesome/pro-solid-svg-icons'
import { faSquare as faslSquare } from '@fortawesome/sharp-light-svg-icons'
import {
  faChevronDown as fassChevronDown,
  faMinus as fassMinus,
  faPlus as fassPlus,
  faSquare as fassSquare,
  faSquareCheck as fassSquareCheck,
} from '@fortawesome/sharp-solid-svg-icons'

// "Supported" icons only
export const ICONS = {
  falCalendarCheck,
  falCalendarHeart,
  falTriangleExclamation,
  farArrowDown,
  farArrowLeft,
  farArrowRight,
  farArrowUp,
  farArrowUpRightFromSquare,
  farBadgeCheck,
  farBadgePercent,
  farChevronRight,
  farEyeSlash,
  farFamilyPants,
  farGiftCard,
  farHouse,
  farHouseLock,
  farInfoCircle,
  farLayerGroup,
  farMoonStars,
  farPaw,
  farPencil,
  farSearch,
  farUtensils,
  farXmark,
  fasBadgeCheck,
  fasBadgePercent,
  fasCalendarHeart,
  fasGiftCard,
  fasHouse,
  fasUser,
  fasUtensils,
  faslSquare,
  fassChevronDown,
  fassMinus,
  fassPlus,
  fassSquare,
  fassSquareCheck,
}
