import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Stack, Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, ICONS, LinkUnstyled, Tile, unit } from '@hcr/ui'

import { Path } from '../../../../models'
import { to } from '../../../../utils'
import {
  Background,
  BookingList,
  ButtonBuyAccommodation,
  LayoutNavigation,
  WarningMissingDataFloating,
} from '../../../common'
import { Coupons } from '../../../coupons'
import { BookingListEmpty } from '../booking-list-empty'
import { BookingListPreview } from '../booking-list-preview'

interface LayoutHomeProps {
  content: ReactNode
  header: ReactNode
  imageUrl: string
  showFallbackWarning?: boolean
}

export const LayoutHome: FC<LayoutHomeProps> = ({ content, header, imageUrl, showFallbackWarning = false }) => {
  const { t } = useTranslation()

  return (
    <LayoutNavigation showContextMenuButton>
      {showFallbackWarning && <WarningMissingDataFloating />}
      <Box paddingX={unit(3)} bgcolor={COLORS.white}>
        <Background url={imageUrl}>
          <Box marginTop={unit(64)}>{header}</Box>
        </Background>
        <Stack gap={unit(3)} paddingY={unit(3)}>
          <Coupons />

          {content}
          <Tile
            cta={<ButtonBuyAccommodation variant='contained' color='primary' />}
            icon={<FontAwesomeIcon icon={ICONS.falCalendarHeart} />}
            title={t('home.your-bookings')}
          >
            <BookingList empty={BookingListEmpty} preview={BookingListPreview} />
          </Tile>
          <Tile
            cta={
              <Button
                component={LinkUnstyled}
                to={to(Path.ContextMenuDestinations)}
                variant='contained'
                color='primary'
              >
                {t('home.browse-destinations')}
              </Button>
            }
            icon={<FontAwesomeIcon icon={ICONS.farSearch} />}
            title={t('home.browse-destinations')}
          >
            <Typography variant='bodyM'>{t('home.explore-and-book-services')}</Typography>
          </Tile>
        </Stack>
      </Box>
    </LayoutNavigation>
  )
}
